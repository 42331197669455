<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="extraction"
          sort-by="id"
          :headers="filteredHeaders"
          :search-filter="true"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #item.delete="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  x-small
                  :disabled="!actionIsEnabled(item)"
                  class="cursor-pointer mx-n1"
                  @click="deleteExtraction(item)"
                  v-on="on"
                >
                  <v-icon color="primary" small> far fa-trash-alt </v-icon>
                </v-btn>
              </template>
              <span>Delete Extraction</span>
            </v-tooltip>
          </template>
          <template #item.progress="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  x-small
                  :disabled="!actionIsEnabled(item)"
                  class="cursor-pointer mx-n1"
                  v-bind="attrs"
                  @click="getExtractionStatus(item)"
                  v-on="on"
                >
                  <v-icon small color="primary"> fa-clock </v-icon>
                </v-btn>
              </template>
              <span>Check Extraction Progress</span>
            </v-tooltip>
          </template>
          <template #item.interval="{ item }">
            <span>{{ secondsToHms(item.interval) }}</span>
          </template>
          <template #item.createdAt="{ item }">
            <span>{{ parseDateTime(item.createdAt, true) }}</span>
          </template>
          <template #item.fromDate="{ item }">
            <span>{{ parseDateTime(item.fromDate) }}</span>
          </template>
          <template #item.toDate="{ item }">
            <span>{{ parseDateTime(item.toDate) }}</span>
          </template>
          <template #item.injectToCr="{ item }">
            <ShowActiveValue :value="item.injectToCr" />
          </template>
          <template #item.jpegsToDropbox="{ item }">
            <ShowActiveValue :value="item.jpegsToDropbox" />
          </template>
        </ReportTable>
        <v-dialog v-model="showStatusModal" max-width="300">
          <v-card>
            <v-card-title>Snpshot Extraction Status</v-card-title>
            <v-divider />
            <v-card-text class="pt-5">
              <v-simple-table class="custom-data-table" dense>
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">Jpegs Extracted</th>
                      <th class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ extractedJpegs }}</td>
                      <td>{{ status }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="showStatusModal = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import SnapshotExtractionsHeader from "@/components/snapshotExtractions/snapshotExtractionsHeaders"
import filterFields from "@/components/snapshotExtractions/snapshotExtractionsSearchFilters"
import { secondsToHms } from "@evercam/shared/utils"
import ReportTable from "@/components/ReportTable"
import ShowActiveValue from "@/components/ShowActiveValue"
import {
  SnapshotExtractionStatus,
  SnapshotExtractionType,
} from "@evercam/shared/types"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.CAMERAS.VIEW_EXTRACTIONS],
  },
  name: "SnapshotExtractions",
  components: {
    ReportTable,
    ShowActiveValue,
  },
  data() {
    return {
      headers: SnapshotExtractionsHeader,
      showStatusModal: false,
      extractedJpegs: 0,
      status: "null",
      filterFields,
    }
  },
  head() {
    return {
      title: "Admin - Snapshot Extractions",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    provider() {
      return AdminApi.cameras.getSnapshotExtractions
    },
    canDeleteExtraction() {
      return this.$permissions.user.can(PERMISSIONS.CAMERAS.DELETE_EXTRACTIONS)
    },
    filteredHeaders() {
      return this.headers.filter(
        (header) => this.canDeleteExtraction || header.value !== "delete"
      )
    },
  },
  methods: {
    actionIsEnabled(item) {
      return (
        this.isProcessing(item.status) &&
        item.type !== SnapshotExtractionType.Timelapse
      )
    },
    isProcessing(status) {
      return status === SnapshotExtractionStatus.Processing
    },
    parseDateTime(dateTime, created) {
      return created
        ? this.$moment(dateTime).format("DD MMM YYYY HH:mm:ss")
        : this.$moment(dateTime).format("DD MMM YYYY")
    },
    secondsToHms,
    async getExtractionStatus(extraction) {
      try {
        const { jpegs, status } =
          await AdminApi.extractions.getExtractionStatus(
            extraction.cameraExid,
            extraction.id,
            {
              type: extraction.type,
            }
          )
        this.extractedJpegs = jpegs
        this.status = status
        this.showStatusModal = true
      } catch (error) {
        this.showStatusModal = false
        this.$notifications.error({
          text: "Could not load extraction status",
          error,
        })
      }
    },
    async deleteExtraction(extraction) {
      if (
        await this.$confirmDialog.open({
          title: "Delete snapshot extraction event ?",
          message: "This will delete snapshot extraction event!",
        })
      ) {
        try {
          await AdminApi.extractions.deleteSnapshotExtraction(
            extraction.cameraExid,
            extraction.id,
            {
              type: extraction.type,
            }
          )
          this.reportStore.items = this.reportStore.items.filter(
            (item) => item.id !== extraction.id
          )
          this.$notifications.success("Snapshot Extaction has been deleted.")
        } catch (error) {
          this.$notifications.error({
            text: "Delete snapshot extraction failed!",
            error,
          })
        }
      }
    },
  },
}
</script>
